import axios from 'axios';

const config = {
  'Content-Type': 'multipart/form-data',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  },
};

const client = axios.create(config);
client.interceptors.response.use((response) => {
  if (response.headers['x-redirect']) {
    window.location = response.headers['x-redirect'];
  }
  return response;
});

export default client;
