/* eslint-disable */
import cn from 'classnames'
import React from 'react'
import { string, arrayOf } from 'prop-types'

import useModal from 'store'

const ModalButton = ({
  buttonCaption,
  modificator,
  goal,
  google,
  pageSlug,
  formTag,
  goalForm,
  googleForm,
  successGoal,
}) => {
  const [, actions] = useModal()
  const isDevelopment = process.env.NODE_ENV === 'development'

  const handleClick = () => {
    actions.open()
    actions.setData({ pageSlug, formTag, goalForm, googleForm, successGoal })
    if (goal && window.yaCounter25586834) {
      if (process.env.NODE_ENV === 'development')
        window.console.log(`click: ${goal}`)
      window.yaCounter25586834.reachGoal(goal)
      window.yaCounter15623191?.reachGoal(goal)
    }
    if (google && window.ga) {
      const googleEvents = google.join(', ')
      if (isDevelopment) window.console.log(`google submit: ${googleEvents}`)
      if (isDevelopment) window.console.log(google)
      window.ga(...google)
    }
    return true
  }

  const buttonCN = cn('button button_try_for_free', modificator)

  return (
    <>
      <button
        area-label={buttonCaption}
        className={buttonCN}
        onClick={handleClick}
        type="button"
      >
        {buttonCaption}
      </button>
    </>
  )
}

ModalButton.propTypes = {
  buttonCaption: string.isRequired,
  pageSlug: string,
  formTag: string.isRequired,
  goal: string,
  google: arrayOf(string),
  modificator: string,
  goalForm: string,
  googleForm: arrayOf(string),
  successGoal: string,
}

ModalButton.defaultProps = {
  goal: 'null',
  google: null,
  goalForm: 'null',
  googleForm: null,
  modificator: null,
  pageSlug: '/',
  successGoal: null,
}

export default ModalButton
/* eslint-enable */
