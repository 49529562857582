import React from 'react';

import style from './preloader-icon.module.sass';

const size = 48;
const color = '#0066CA';

const Preloader = () => (
  <div className={style.icon}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={style.inner}
    >
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="-0.5756578947368421s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
        transform="rotate(45 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="-0.4934210526315789s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
        transform="rotate(90 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="-0.41118421052631576s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
        transform="rotate(135 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="-0.32894736842105265s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
        transform="rotate(180 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="-0.24671052631578946s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
        transform="rotate(225 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="-0.16447368421052633s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
        transform="rotate(270 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="-0.08223684210526316s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x="47.5"
        y="11"
        rx="2.5"
        ry="3.08"
        width="5"
        height="22"
        fill={color}
        transform="rotate(315 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.6578947368421053s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  </div>
);

export default Preloader;
