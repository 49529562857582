/* eslint-disable react/jsx-props-no-spreading */

import cn from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';
import { string, func, bool } from 'prop-types';

import ErrorMessage from '../error-message/error-message';

import './input.sass';

const Input = ({
  name, validate, placeholder, type, isDisabled,
}) => (
  <Field
    name={name}
    validate={validate}
  >
    {({ meta, input }) => {
      const isError = meta.error && meta.touched;
      const cnInput = cn('input', { 'input--error': isError });
      return (
        <div className="form__field" style={{ width: '100%' }}>
          <input
            {...input}
            className={cnInput}
            disabled={isDisabled}
            maxLength={100}
            placeholder={placeholder}
            type={type}
          />
          {isError && <ErrorMessage>{meta.error}</ErrorMessage>}
        </div>
      );
    }}
  </Field>
);

Input.propTypes = {
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  type: string,
  validate: func,
};

Input.defaultProps = {
  isDisabled: false,
  placeholder: null,
  type: 'text',
  validate: null,
};

export default Input;
