/* eslint-disable */
class HeaderFixed {
  init() {
    this.headerFixedCheckScroll()
    document.addEventListener('scroll', () => {
      this.headerFixedCheckScroll()
    })
  }

  headerFixedCheckScroll() {
    const header = document.querySelector('.header')
    const navigation = document.querySelector('.navigation')
    if (window.scrollY > 40 && !header.classList.contains('header_fixed')) {
      header.classList.add('header_fixed')
      navigation.classList.add('navigation_fixed')
    } else if (
      window.scrollY <= 40 &&
      header.classList.contains('header_fixed')
    ) {
      header.classList.remove('header_fixed')
      navigation.classList.remove('navigation_fixed')
    }
  }
}

export const headerFixed = new HeaderFixed()
/* eslint-enable */
