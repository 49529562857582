import React from 'react';

import styles from './privacy.module.sass';

const Privacy = () => (
  <div className={styles.privacy}>
    <span>Отправляя форму вы даете</span>
    {' '}
    <a
      href="/personal"
      className="link link--dark link--decorated"
      title="согласие на обработку персональных данных"
    >
      согласие на обработку персональных данных
    </a>
    <span> и на</span>
    {' '}
    <a
      href="/marketing-communications"
      className="link link--dark link--decorated"
      title="маркетинговые коммуникации"
    >
      маркетинговые коммуникации
    </a>
  </div>
);

export default Privacy;
