/* eslint-disable */
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

class HomeAnimate {
  init() {
    if (!document.querySelector('.home-hero')) return
    gsap.registerPlugin(ScrollTrigger)
    this.animateAll()
    this.parallaxHero()
    this.parallaxTasks()
    this.parallaxInfo()
    this.parallaxContact()
  }

  animateAll() {
    document.querySelectorAll('.animate').forEach((el, i) => {
      const delay = parseFloat(el.dataset.delay) || 0
      gsap.fromTo(
        el,
        {
          opacity: 0,
          y: 100,
        },
        {
          scrollTrigger: {
            id: 'animate_' + i,
            trigger: el,
            markers: false,
          },
          duration: 0.5,
          delay,
          opacity: 1,
          y: 0,
          ease: 'circ',
        }
      )
    })
  }

  parallaxHero() {
    const el = document.querySelector('.home-hero__background')
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        start: 'top -10%',
        end: 'bottom 40%',
        scrub: 1,
      },
      y: 70,
    })
  }

  parallaxTasks() {
    const el = document.querySelector('.home-tasks__background')
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        start: 'top 80%',
        end: 'bottom 40%',
        scrub: 1,
      },
      y: 150,
    })
  }

  parallaxInfo() {
    const top = document.querySelector('.home-info__background_top')
    const bottom = document.querySelector('.home-info__background_bottom')
    gsap.to(top, {
      scrollTrigger: {
        trigger: top,
        start: 'top 20%',
        end: 'bottom 0',
        scrub: 1,
      },
      y: 150,
    })
    gsap.to(bottom, {
      scrollTrigger: {
        trigger: bottom,
        start: 'top 50%',
        end: 'bottom 0',
        scrub: 1,
      },
      y: 70,
    })
  }

  parallaxContact() {
    const el = document.querySelector('.home-contact__background')
    gsap.to(el, {
      scrollTrigger: {
        trigger: el,
        start: 'top 40%',
        end: 'bottom 40%',
        scrub: 1,
      },
      y: 150,
    })
  }
}

export const homeAnimate = new HomeAnimate()
/* eslint-enable */
