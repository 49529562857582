import axios from 'utils/axios';
import snakecaseKeys from 'snakecase-keys';

const clientRequest = async (data, token) => {
  const endpoint = '/client_requests';
  const dataSnake = snakecaseKeys(data);
  const addToken = { 'g-recaptcha-response-data': token };
  const bodyRequest = { ...dataSnake, ...addToken };
  try {
    const response = await axios.post(endpoint, bodyRequest);

    return response;
  } catch (error) {
    return error;
  }
};

export default clientRequest;
