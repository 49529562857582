import cn from 'classnames';
import React from 'react';
import { string, bool } from 'prop-types';

import styles from './error-message.module.sass';

const ErrorMessage = ({ children, inverse, className }) => {
  const messageCN = cn(
    styles.errorMessage,
    { [styles.inverse]: inverse },
    className,
  );
  return <div className={messageCN}>{children}</div>;
};

ErrorMessage.propTypes = {
  children: string.isRequired,
  className: string,
  inverse: bool,
};

ErrorMessage.defaultProps = {
  className: null,
  inverse: false,
};

export default ErrorMessage;
