import cn from 'classnames';
import React from 'react';
import { string, bool } from 'prop-types';

const Submit = ({ caption, disabled, isSubmitting }) => {
  const cnSubmit = cn('button button_default home-contact-form__submit');

  return (
    <button
      type="submit"
      style={{ width: '100%' }}
      className={cnSubmit}
      disabled={disabled || isSubmitting}
    >
      {caption}
    </button>
  );
};

Submit.propTypes = {
  caption: string,
  disabled: bool,
  isSubmitting: bool,
};

Submit.defaultProps = {
  caption: 'Отправить',
  disabled: false,
  isSubmitting: false,
};

export default Submit;
