import React from 'react';
import { string } from 'prop-types';

import ArticleForm from 'block/article-form';
import { InputName, InputEmail, InputPhone } from 'UI/form';

const DocumentForm = ({
  formTag,
  formType,
  goal,
  message,
  pageSlug,
  scrollButtonCaption,
  submitCaption,
  successMessage,
  title,
  subtitle,
}) => (
  <ArticleForm
    formTag={formTag}
    formType={formType}
    goal={goal}
    message={message}
    pageSlug={pageSlug}
    scrollButtonCaption={scrollButtonCaption}
    submitCaption={submitCaption}
    successMessage={successMessage}
    title={title}
    subtitle={subtitle}
  >
    <InputName placeholder="Имя" />
    <InputPhone />
    <InputEmail />
  </ArticleForm>
);

DocumentForm.propTypes = {
  formTag: string.isRequired,
  formType: string,
  goal: string,
  message: string,
  pageSlug: string,
  scrollButtonCaption: string,
  submitCaption: string,
  successMessage: string,
  title: string,
  subtitle: string,
};

DocumentForm.defaultProps = {
  formType: null,
  goal: null,
  message: null,
  pageSlug: null,
  scrollButtonCaption: null,
  submitCaption: null,
  successMessage: null,
  title: null,
  subtitle: null,
};

export default DocumentForm;
