/* eslint-disable react/jsx-props-no-spreading */

import cn from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';
import { string, func, bool } from 'prop-types';
import { email, required, composeValidators } from 'utils/validation';

import ErrorMessage from '../error-message/error-message';

import styles from './subscribe.module.sass';

const Subscribe = () => (
  <Field name="email" validate={composeValidators(required, email)}>
    {({ meta, input }) => {
      const isError = meta.error && meta.touched;
      const isSubmitting = meta.submitting;

      return (
        <div className={cn(styles.container, { [styles.error]: isError })}>
          <input
            {...input}
            className={styles.input}
            maxLength={100}
            placeholder="Ваш E-mail"
            type="email"
            disabled={isSubmitting}
          />
          <button
            type="submit"
            className={styles.submit}
            disabled={isSubmitting}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                d="M21.606 11.58H2.5m14.062-4.969l5.044 4.97-5.044 4.97"
              />
            </svg>
          </button>

          {isError && (
            <ErrorMessage className={styles.errorMessage} inverse>
              {meta.error}
            </ErrorMessage>
          )}
        </div>
      );
    }}
  </Field>
);

Subscribe.propTypes = {
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  type: string,
  validate: func,
};

Subscribe.defaultProps = {
  isDisabled: false,
  placeholder: null,
  type: 'text',
  validate: null,
};

export default Subscribe;
