import cn from 'classnames';
import Dangerous from 'dangerous-components';
import React, { useState, useRef } from 'react';
import { Element } from 'react-scroll';
import { string, node, arrayOf } from 'prop-types';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import tp from 'utils/tp';

import { LeadForm, BaseForm } from 'form';

import Privacy from 'block/privacy';
import ScrollButton from './components/scroll-button';

import styles from './article-form.module.sass';

const ArticleForm = ({
  children,
  formTag,
  formType,
  goal,
  google,
  message,
  pageSlug,
  scrollButtonCaption,
  submitCaption,
  successMessage,
  title,
  subtitle,
  successGoal,
}) => {
  const formRef = useRef(null);
  const [showButton, setShowButton] = useState(false);

  function getCoords(elem) {
    const box = elem.getBoundingClientRect();

    return {
      bottom: Math.round(box.bottom),
    };
  }

  useScrollPosition(
    ({ currPos }) => {
      const container = document.querySelector('.article__content');
      const isContainerEnd = getCoords(container).bottom - document.body.offsetHeight < 0;
      const isShow = currPos.y < -400 && !isContainerEnd;
      setShowButton(isShow);
    },
    [],
    formRef,
  );

  return (
    <>
      <div className={styles.articleForm} ref={formRef}>
        <Element name="article-form">
          {showButton && <ScrollButton text={scrollButtonCaption} />}

          <div
            className={cn(styles.container, {
              [styles.containerWide]: !message,
            })}
          >
            <div className={styles.left}>
              <div className={styles.form}>
                <LeadForm
                  formTag={formTag}
                  formType={formType}
                  goal={goal}
                  google={google}
                  pageSlug={pageSlug}
                  successMessage={successMessage}
                  successGoal={successGoal}
                  formTitle={title}
                  formBody={message}
                  submitCaption={submitCaption}
                >
                  <BaseForm
                    title={title}
                    subtitle={subtitle}
                    submitCaption={submitCaption}
                  >
                    {children}
                  </BaseForm>
                </LeadForm>
              </div>
            </div>

            {message && (
              <div className={styles.right}>
                {message && (
                  <Dangerous
                    className={styles.message}
                    html={tp(message)}
                    as="div"
                  />
                )}
              </div>
            )}
          </div>
        </Element>
      </div>
      <Privacy />
    </>
  );
};

ArticleForm.propTypes = {
  children: node.isRequired,
  formTag: string.isRequired,
  formType: string,
  goal: string,
  google: arrayOf(string),
  message: string,
  pageSlug: string,
  scrollButtonCaption: string,
  submitCaption: string,
  successMessage: string,
  successGoal: string,
  title: string.isRequired,
  subtitle: string,
};

ArticleForm.defaultProps = {
  formType: null,
  goal: null,
  google: null,
  message: null,
  pageSlug: null,
  scrollButtonCaption: null,
  submitCaption: null,
  successMessage: null,
  successGoal: null,
  subtitle: null,
};

export default ArticleForm;
