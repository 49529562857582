import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
  initialState: {
    isOpen: false,
    data: {},
  },

  actions: {
    toggle:
      () => ({ setState, getState }) => {
        const { isOpen } = getState();
        setState({
          isOpen: !isOpen,
        });
      },
    open:
      () => ({ setState }) => {
        setState({
          isOpen: true,
        });
      },
    close:
      () => ({ setState }) => {
        setState({
          isOpen: false,
        });
      },
    setData:
      (data) => ({ setState }) => {
        setState({
          data: {
            ...data,
          },
        });
      },
  },
  name: 'modal',
});

const useModal = createHook(Store);

export default useModal;
