/* eslint-disable */
import { isTablet } from '../utils'

class FooterTablet {
  init() {
    if (isTablet()) {
      const signIn = document.querySelector('.footer-controls__sign-in')
      const container = document.querySelector('.footer__navigation .container')
      container.append(signIn)
    }
  }
}

export const footerTablet = new FooterTablet()
/* eslint-enable */
