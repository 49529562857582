import React from 'react';
import { required, maxLength, composeValidators } from 'utils/validation';

import { Input } from '../input';

const InputPhone = () => (
  <Input
    name="phone"
    placeholder="Телефон"
    type="tel"
    validate={composeValidators(required, maxLength(20))}
  />
);

export default InputPhone;
