import React from 'react';
import {
  email, required, composeValidators,
} from 'utils/validation';

import { Input } from '../input';

const InputEmail = () => (
  <Input
    name="email"
    placeholder="E-mail"
    type="email"
    validate={composeValidators(required, email)}
  />
);

export default InputEmail;
