import React from 'react';
import { oneOfType, node, string } from 'prop-types';
import { Tween } from 'react-gsap';

const FadeIn = ({ children }) => (
  <Tween
    from={{ y: '30px', opacity: 0 }}
    delay={0.1}
    duration={0.5}
    ease="back.out(1.7)"
  >
    <div>{children}</div>
  </Tween>
);

FadeIn.propTypes = {
  children: oneOfType([node, string]).isRequired,
};

export default FadeIn;
