const formData = {
  company: null,
  description: null,
  email: null,
  formTag: null,
  formType: null,
  name: null,
  pageSlug: null,
  phone: null,
  yaClientId: null,
};

export default formData;
