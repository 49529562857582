import Dangerous from 'dangerous-components';
import React from 'react';
import { string, node } from 'prop-types';
import { useFormState } from 'react-final-form';

import { FadeIn, FieldsFadeIn } from 'animation';
import { Submit } from '../UI/form';

import styles from './form.module.sass';

const BaseForm = ({
  children, title, subtitle, submitCaption,
}) => {
  const { submitting } = useFormState();

  return (
    <>
      {title && (
        <legend>
          <Dangerous as="h3" html={title} className={styles.title} />
        </legend>
      )}
      {subtitle && (
        <legend>
          <Dangerous as="div" html={subtitle} className={styles.subtitle} />
        </legend>
      )}

      <div className="form__body">
        <FieldsFadeIn>{children}</FieldsFadeIn>
      </div>

      <div className="form__submit">
        <FadeIn>
          <Submit
            disabled={submitting}
            isSubmitting={submitting}
            caption={submitCaption}
          />
        </FadeIn>
      </div>
    </>
  );
};

BaseForm.propTypes = {
  children: node.isRequired,
  submitCaption: string,
  title: string,
  subtitle: string,
};

BaseForm.defaultProps = {
  submitCaption: null,
  title: null,
  subtitle: null,
};

export default BaseForm;
