/* eslint-disable react/jsx-props-no-spreading */

import cn from 'classnames';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Field } from 'react-final-form';
import {
  string, func, bool, number,
} from 'prop-types';

const TextArea = ({
  name, validate, placeholder, isDisabled, rows,
}) => (
  <Field
    name={name}
    validate={validate}
  >
    {({ meta, input }) => {
      const cnInput = cn('input', { 'input--error': meta.error && meta.touched });
      return (
        <TextareaAutosize
          {...input}
          className={cnInput}
          disabled={isDisabled}
          placeholder={placeholder}
          minRows={rows}
          maxRows={15}
        />
      );
    }}
  </Field>
);

TextArea.propTypes = {
  isDisabled: bool,
  name: string.isRequired,
  placeholder: string,
  rows: number,
  type: string,
  validate: func,
};

TextArea.defaultProps = {
  isDisabled: false,
  placeholder: null,
  rows: 5,
  type: 'text',
  validate: null,
};

export default TextArea;
