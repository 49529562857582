import EmailValidator from 'email-validator';

const email = (value) => (EmailValidator.validate(value)
  ? null
  : 'Укажите корректный адрес электронной почты');
const maxLength = (max) => (value) => (value.length <= max ? null : `Не должно превышать ${max} символов`);
const mustBeNumber = (value) => (Number.isNaN(value) ? 'Должно быть числом' : null);
const required = (value) => (value ? null : 'Обязательное поле');

const composeValidators = (...validators) => (value) => validators.reduce(
  (error, validator) => error || validator(value),
  undefined,
);

export {
  required, composeValidators, email, maxLength, mustBeNumber,
};
