import cn from 'classnames';
import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form } from 'react-final-form';
import {
  string, node, oneOf, arrayOf, object,
} from 'prop-types';
import { useCookies } from 'react-cookie';

import { clientRequest } from 'api';

import { FadeIn } from 'animation';
import { ErrorMessage, Success } from '../UI/form';

import formData from './form-data';

import styles from './form.module.sass';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const siteKey = '6Lelda0ZAAAAAP6Kw3YXflFxodwFpngaOMT6t2AR';

const LeadForm = ({
  children,
  className,
  formTag,
  formType,
  formTitle,
  formBody,
  goal,
  google,
  pageSlug,
  successMessage,
  successGoal,
  submitCaption,
  customHiddenFields, // hack for adding non-empty "name" for subscribe form (ersatz instead of backend fixing)
}) => {
  const [formState, setFormState] = useState('READY');
  const [cookies] = useCookies();
  const recaptchaRef = useRef();
  const hiddenFields = {
    formTag,
    formType,
    pageSlug,
    formTitle,
    formBody,
    submitCaption,
    utm: cookies.utm,
    yaClientId: cookies._ym_uid,
  };
  const isError = formState === 'ERROR';
  const isSuccess = formState === 'OK';
  const isWaiting = formState === 'WAITING';
  const isDevelopment = process.env.NODE_ENV === 'development';

  const handleFormReset = () => setFormState('READY');
  const handleFormError = () => setFormState('ERROR');
  const handleFormWaiting = () => setFormState('WAITING');
  const handleGoal = () => {
    if (goal && window.yaCounter25586834) {
      window.yaCounter25586834.reachGoal(goal);
      window.yaCounter15623191?.reachGoal(goal);
      if (isDevelopment) window.console.log(`yandex submit: ${goal}`);
    }
    if (google && window.ga) {
      const googleEvents = google.join(', ');
      if (isDevelopment) window.console.log(`google submit: ${googleEvents}`);
      window.ga(...google);
    }
    return true;
  };

  const handleFormSuccess = () => {
    setFormState('OK');
    handleGoal();
  };

  const onSubmit = async (values) => {
    handleFormWaiting();
    await sleep(300);
    const data = { clientRequest: { ...values } };

    if (isDevelopment) window.console.log(data.clientRequest);

    const token = await recaptchaRef.current.executeAsync();
    if (token) {
      clientRequest(data, token).then((result) => {
        if (result.data?.status === 'OK') return handleFormSuccess();
        return handleFormError();
      });

      recaptchaRef.current.reset();
      return true;
    }

    recaptchaRef.current.reset();
    return handleFormError();
  };

  if (!children) return <ErrorMessage>Нужно передать поля для формы!</ErrorMessage>;

  if (isSuccess && formType === 'subscribe') {
    window.yaCounter25586834?.reachGoal('thnkrss');
    window.yaCounter15623191?.reachGoal('thnkrss');
    window.ga('send', 'event', 'thnkrss', 'click');
  }

  if (isSuccess) {
    return formType === 'subscribe' ? (
      <FadeIn>
        <p
          className="sForm__succes js-subscribeForm-succes"
          style={{ display: 'block' }}
        >
          {successMessage}
        </p>
      </FadeIn>
    ) : (
      <Success
        close={handleFormReset}
        successMessage={successMessage}
        successGoal={successGoal}
      />
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      formState={formState}
      initialValues={{
        ...formData,
        ...hiddenFields,
        ...customHiddenFields,
      }}
      render={({ handleSubmit, form, submitting }) => (
        <form
          className={cn(
            styles.form,
            { [styles.formDisabled]: submitting || isWaiting },
            className,
          )}
          onSubmit={handleSubmit}
        >
          {children}

          <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} size="invisible" />

          {isError && (
            <footer className={styles.footer}>
              <ErrorMessage>
                Упс! При отправке произошла ошибка, пожалуйста, попробуйте еще
                раз или используйте другой вид связи.
              </ErrorMessage>
            </footer>
          )}
        </form>
      )}
    />
  );
};

LeadForm.propTypes = {
  children: node.isRequired,
  className: string,
  goal: string,
  google: arrayOf(string),
  formType: oneOf(
    ['main', 'webinar', 'subscribe', 'partners', 'document'],
    string,
  ),
  formTag: string.isRequired,
  formTitle: string,
  formBody: string,
  pageSlug: string,
  successMessage: string,
  successGoal: string,
  submitCaption: string,
  customHiddenFields: object,
};

LeadForm.defaultProps = {
  className: null,
  formType: null,
  formTitle: null,
  formBody: null,
  goal: null,
  google: null,
  pageSlug: null,
  successMessage: null,
  successGoal: null,
  submitCaption: null,
  customHiddenFields: {},
};

export default LeadForm;
