import React from 'react';
import { string, arrayOf } from 'prop-types';

import ArticleForm from 'block/article-form';
import { InputName, InputEmail } from 'UI/form';

const ArticleFormComponent = ({
  formTag,
  formType,
  goal,
  google,
  message,
  pageSlug,
  scrollButtonCaption,
  submitCaption,
  successMessage,
  title,
  successGoal,
}) => (
  <ArticleForm
    formTag={formTag}
    formType={formType}
    goal={goal}
    google={google}
    message={message}
    pageSlug={pageSlug}
    scrollButtonCaption={scrollButtonCaption}
    submitCaption={submitCaption}
    successMessage={successMessage}
    successGoal={successGoal}
    title={title}
  >
    <InputName />
    <InputEmail />
  </ArticleForm>
);

ArticleFormComponent.propTypes = {
  formTag: string.isRequired,
  formType: string,
  goal: string,
  google: arrayOf(string),
  message: string,
  pageSlug: string,
  scrollButtonCaption: string,
  submitCaption: string,
  successMessage: string,
  title: string,
  successGoal: string,
};

ArticleFormComponent.defaultProps = {
  formType: null,
  goal: null,
  google: null,
  message: null,
  pageSlug: null,
  scrollButtonCaption: null,
  submitCaption: null,
  successMessage: null,
  title: null,
  successGoal: null,
};

export default ArticleFormComponent;
