import React from 'react';
import { FadeIn } from 'animation';
import { Link } from 'react-scroll';
import { string } from 'prop-types';

import './scroll-button.sass';

const ScrollButton = ({ text }) => {
  if (!text) return null;
  return (
    <Link
      className="scroll-button"
      duration={600}
      offset={-90}
      smooth
      to="article-form"
    >
      <FadeIn>
        <span className="scroll-button__inner">{text}</span>
      </FadeIn>
    </Link>
  );
};

ScrollButton.propTypes = {
  text: string,
};

ScrollButton.defaultProps = {
  text: null,
};

export default ScrollButton;
