/* eslint-disable */
import { gsap } from 'gsap'
class Burger {
  button
  html
  showClass = 'navigation_show'
  hideClass = 'navigation_hide'
  timeout = 500
  navigation

  constructor() {}

  init() {
    this.button = document.querySelector('.button-burger')
    this.html = document.querySelector('html')
    this.navigation = document.querySelector('.navigation')

    if (this.button) {
      this.button.addEventListener('click', () => {
        if (this.html.classList.contains(this.showClass)) {
          this.hideNavigation()
        } else {
          this.showNavigation()
        }
      })
    }

    if (this.navigation) {
      this.navigation.querySelectorAll('a').forEach((el) => {
        el.addEventListener('click', () => {
          this.hideNavigation()
        })
      })
    }
  }

  hideNavigation() {
    this.html.classList.remove(this.showClass)
    this.html.classList.add(this.hideClass)
    gsap.to('.header__navigation', {
      opacity: 1,
      duration: 0.5,
      display: 'flex',
    })
    setTimeout(() => {
      this.html.classList.remove(this.hideClass)
    }, this.timeout)
  }

  showNavigation() {
    this.html.classList.add(this.showClass)
    gsap.to('.header__navigation', {
      opacity: 0,
      duration: 0.5,
      display: 'none',
    })
  }
}

export const burger = new Burger()
/* eslint-enable */
