import Dangerous from 'dangerous-components';
import React from 'react';
import { func, string } from 'prop-types';
import { Tween } from 'react-gsap';

import tp from 'utils/tp';

import Icon from './icon';

import styles from './success.module.sass';

const Success = ({ close, successMessage, successGoal }) => {
  // window.yaCounter25586834.reachGoal(successGoal);
  // window.yaCounter15623191.reachGoal(successGoal);
  window.ga('send', 'event', successGoal, 'click');

  return (
    <div className={styles.success}>
      <header className={styles.header}>
        <span className={styles.icon}>
          <Icon />
        </span>

        <Tween from={{ y: 20, opacity: 0 }} delay={0.1} duration={0.5}>
          <h6 className={styles.title}>Заявка принята!</h6>
        </Tween>
      </header>

      {successMessage && (
        <Tween from={{ y: 20, opacity: 0 }} delay={0.2} duration={0.5}>
          <div>
            <Dangerous
              className={styles.message}
              html={tp(successMessage)}
              as="p"
            />
          </div>
        </Tween>
      )}

      <Tween from={{ y: 20, opacity: 0 }} delay={0.3} duration={0.5}>
        <button
          area-label="Вернуться к форме"
          className={styles.button}
          onClick={close}
          type="button"
        >
          Вернуться к форме
        </button>
      </Tween>
    </div>
  );
};

Success.propTypes = {
  close: func.isRequired,
  successMessage: string,
  successGoal: string,
};

Success.defaultProps = {
  successMessage: null,
  successGoal: null,
};

export default Success;
