import PureModal from 'react-pure-modal';
import React, { lazy, Suspense } from 'react';
import useKeypress from 'react-use-keypress';

import PreloaderIcon from 'UI/preloader-icon';

import useModal from 'store';

import 'screen/modal.scss';

const ModalForm = lazy(() => import('block/modal-form'));

const Modal = () => {
  const [state, actions] = useModal();

  useKeypress('Escape', () => {
    actions.close();
  });

  const {
    isOpen,
    data: { formTag, pageSlug, goalForm, googleForm, successGoal },
  } = state;

  if (!isOpen) return null;

  return (
    <PureModal
      isOpen={isOpen}
      onClose={actions.close}
      scrollable={false}
      title=""
      closeButton=""
    >
      <Suspense fallback={<PreloaderIcon />}>
        <ModalForm
          formTag={formTag}
          goal={goalForm}
          google={googleForm}
          successGoal={successGoal}
          pageSlug={pageSlug}
          title="Попробуйте нас в деле!"
        />
      </Suspense>
    </PureModal>
  );
};

export default Modal;
