import React from 'react';
import { string } from 'prop-types';

import { required, composeValidators, maxLength } from 'utils/validation';

import { Input } from '../input';

const InputName = ({ placeholder }) => (
  <Input
    name="name"
    placeholder={placeholder}
    type="text"
    validate={composeValidators(required, maxLength(40))}
  />
);

InputName.propTypes = {
  placeholder: string,
};

InputName.defaultProps = {
  placeholder: 'Имя и фамилия',
};

export default InputName;
