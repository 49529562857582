import React from 'react';
import { arrayOf, node } from 'prop-types';
import { Tween } from 'react-gsap';
import { useId } from 'react-id-generator';

const FieldsFadeIn = ({ children }) => {
  const idList = useId(children.length);

  return (
    <Tween
      from={{ y: '30px', opacity: 0 }}
      stagger={0.1}
      ease="elastic.out(0.2, 0.1)"
    >
      {children.map((field, i) => (
        <div key={idList[i]}>{field}</div>
      ))}
    </Tween>
  );
};

FieldsFadeIn.propTypes = {
  children: arrayOf(node).isRequired,
};

export default FieldsFadeIn;
