/* eslint-disable */
class ScrollTop {
  init() {
    const scrollTop = document.querySelector('.scroll-top')
    if (!scrollTop) return

    scrollTop.onclick = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    this.checkScroll(scrollTop)

    window.addEventListener('scroll', () => {
      this.checkScroll(scrollTop)
    })
  }

  /**
   *
   * @param scrollTop {HTMLElement}
   */
  checkScroll(scrollTop) {
    if (window.scrollY === 0) {
      scrollTop.classList.add('scroll-top_hide')
    } else if (scrollTop.classList.contains('scroll-top_hide')) {
      scrollTop.classList.remove('scroll-top_hide')
    }
  }
}

export const scrollTop = new ScrollTop()
/* eslint-enable */
