import React from 'react';
import { Tween } from 'react-gsap';

const Icon = () => (
  <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#FFF" fill="none" fillRule="evenodd">
      <Tween
        duration={0.6}
        ease="power1.out"
        from={{
          svgDraw: 0,
        }}
        to={{
          svgDraw: 1.1,
        }}
      >
        <circle strokeWidth="10" cx="50" cy="50" r="45" />

      </Tween>
      <Tween
        ease="back.out(1.7)"
        duration={1.5}
        from={{
          svgDraw: 0,
        }}
        to={{
          svgDraw: 1,
        }}
      >
        <path strokeWidth="12" strokeLinecap="round" d="M25.654 50l14.979 15.473 31.454-31.38" />
      </Tween>
    </g>
  </svg>
);

export default Icon;
