import React from 'react';
import { string, arrayOf } from 'prop-types';
import { LeadForm } from 'form';
import { Subscribe } from 'UI/form';

const SubscribeForm = ({
  formTag,
  formType,
  goal,
  google,
  pageSlug,
  successMessage,
  title,
  formTitle,
}) => (
  <LeadForm
    formTag={formTag}
    formType={formType}
    goal={goal}
    google={google}
    pageSlug={pageSlug}
    successMessage={successMessage}
    submitCaption="Подписаться"
    title={title}
    formTitle={formTitle}
    customHiddenFields={{ name: '_subscriber' }}
  >
    <Subscribe />
  </LeadForm>
);

SubscribeForm.propTypes = {
  formTag: string.isRequired,
  formType: string,
  goal: string,
  google: arrayOf(string),
  pageSlug: string,
  successMessage: string,
  title: string,
  formTitle: string,
};

SubscribeForm.defaultProps = {
  formType: null,
  goal: null,
  google: null,
  pageSlug: null,
  successMessage: null,
  title: null,
  formTitle: null,
};

export default SubscribeForm;
